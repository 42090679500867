import { graphql } from 'gatsby';
import Course from '../components/academy/Course';

export default Course;

export const query = graphql`
  query AcademyPageQuery($slug: String!, $locale: String!, $key: String!, $pictures: String!) {
    site {
      siteMetadata {
        siteUrl
        author
        social {
          name
          url
        }
      }
    }
    notionAcademy(slug: { eq: $slug }, locale: { eq: $locale }) {
      properties {
        i18n_key
        name
        status
        abstract
        program
        sessions {
          start_date
          duration
          language
          place
          price
          early_price
          early_until_date
          closed_inscription
        }
      }
    }
    masterImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "masterClass" }
      name: { eq: $key }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      }
    }
    ogImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "masterClass" }
      name: { eq: $key }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    pictures: allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: $pictures } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 584, placeholder: BLURRED)
        }
      }
    }
  }
`;
