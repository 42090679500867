// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getSrc } from 'gatsby-plugin-image';
import type { GatsbyImageFileWithName } from '../../../utils/graphql';
import type { Session } from '../../../utils/graphqlNotion';
import DefaultLayout from '../../layouts/default-layout';
import * as Colors from '../../Colors';
import LinkedDocuments from '../LinkedDocuments';
import Form from '../masterclass/Form';
import Financing from './Financing';
import SessionSticky from './SessionSticky';
import Program from './Program';

type IndexQueryProps = {
  i18n_key: string;
  ogImage: GatsbyImageFileWithName;
  locale: string;
  title: string;
  status?: string;
  abstract: string;
  program: string;
  session: Session | undefined;
};

const Index: React.VoidFunctionComponent<IndexQueryProps> = ({
  i18n_key,
  ogImage,
  locale,
  title,
  status,
  abstract,
  program,
  session,
}) => {
  const { t } = useTranslation('training');

  return (
    <DefaultLayout
      headTitle={title}
      headDescription={abstract}
      headImageSource={getSrc(ogImage)}
      lang={locale}
      theme={{ class: Colors.WHITE_ON_BLACK, pseudoClass: Colors.WHITE_ON_BLACK_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
      showContactForm={true}
    >
      <section id="introduction">
        <div className={`pt-10 ${Colors.WHITE_ON_BLACK}`}>
          <div className="container px-4 py-8 sm:px-8">
            <h1 className="text-left text-5xl font-bold">{title}</h1>
            <p>{abstract}</p>
            <div className="mt-4">
              {session ? (
                <a
                  href="#form"
                  rel="noopener noreferrer"
                  title={t('introduction.button')}
                  className={`mb-4 mr-4 inline-block rounded-1 border-1 border-solid border-white px-4 py-3 text-center font-semibold leading-none ${Colors.WHITE_ON_BLACK} ${Colors.WHITE_ON_BLACK_PSEUDO_CLASS}`}
                >
                  {t('introduction.button')}
                </a>
              ) : null}
              <a
                href={program}
                target="_blank"
                rel="noopener noreferrer"
                title={t('introduction.download-program-button')}
                className={`mr-4 inline-block rounded-1 border-1 border-solid border-white px-4 py-3 text-center font-semibold leading-none ${Colors.WHITE_ON_BLACK} ${Colors.WHITE_ON_BLACK_PSEUDO_CLASS}`}
              >
                {t('introduction.download-program-button')}
              </a>
              {status === 'Draft' ? (
                <span
                  className={`mt-4 inline-block px-4 py-3 font-semibold leading-none ${Colors.WHITE_ON_BLUE}`}
                >
                  Draft
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <div
        className={`container px-4 py-8 sm:px-8 md:grid md:grid-flow-col md:auto-rows-max md:gap-4 ${Colors.BLACK_ON_WHITE}`}
      >
        <SessionSticky
          t={t}
          session={session}
          locale={locale}
          className="md:hidden"
          program={program}
        />
        <section>
          <div>
            <p>{t(`trainings.${i18n_key}.description`)}</p>
          </div>
        </section>
        <section>
          <div>
            <h2 className="text-left text-4xl font-semibold">{t('objectives')}</h2>
            <p className="whitespace-pre-line">{t(`trainings.${i18n_key}.objectives`)}</p>
          </div>
        </section>
        <div className="relative -top-[70px] hidden md:row-span-2 md:block md:max-w-80">
          <SessionSticky
            t={t}
            session={session}
            locale={locale}
            className="md:sticky md:left-full md:top-5"
            program={program}
          />
        </div>
      </div>
      <Program i18n_key={i18n_key} />
      <Financing session={session} />
      {session && !session.closed_inscription ? (
        <Form masterclass={`${i18n_key}#${session.start_date}`} bgColor={Colors.BLACK_ON_YELLOW} />
      ) : null}
      <LinkedDocuments />
    </DefaultLayout>
  );
};

export default Index;
