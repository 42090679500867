import { StaticImage } from 'gatsby-plugin-image';
// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HeadsetSvgIcon from '../../../images/icons/headset.inline.svg';
import { formatToEuro } from '../../../utils/formatNumber';
import type { Session } from '../../../utils/graphqlNotion';
import DiscoverLink from '../../buttons/DiscoverLink';
import * as Colors from '../../Colors';

type FundingProps = {
  funding: string;
  children: React.ReactNode;
};
const Funding: React.VoidFunctionComponent<FundingProps> = ({ funding, children }) => {
  const { t } = useTranslation('training');
  return (
    <div className="m-2 flex h-64 min-w-72 flex-col items-center justify-evenly bg-white">
      {children}
      <div>
        <p className="text-lg font-semibold">
          {
            <Trans
              t={t}
              i18nKey="financing.fundings.coverage"
              components={{ blue: <span className="font-bold text-reacteev-blue" /> }}
            >
              financing.fundings.coverage
            </Trans>
          }
        </p>
        <p className="text-xs">{t(`financing.fundings.${funding}.description`)}</p>
      </div>
    </div>
  );
};

const FundingOpco: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('training');
  return (
    <Funding funding="opco">
      <StaticImage
        src="../../../images/academy/training/opco.png"
        alt={t('financing.fundings.opco.image-alt')}
      />
    </Funding>
  );
};
const FundingPoleEmploi: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('training');
  return (
    <Funding funding="pole-emploi">
      <StaticImage
        src="../../../images/academy/training/pole-emploi.png"
        alt={t('financing.fundings.pole-emploi.image-alt')}
      />
    </Funding>
  );
};
const FundingMonCompteFormation: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('training');
  return (
    <Funding funding="mon-compte-formation">
      <StaticImage
        src="../../../images/academy/training/mon-compte-formation.png"
        alt={t('financing.fundings.mon-compte-formation.image-alt')}
      />
    </Funding>
  );
};

type FinancingProps = {
  session?: Session;
};

const Financing: React.VoidFunctionComponent<FinancingProps> = ({ session }) => {
  const { t } = useTranslation('training');
  const { t: tAcademy } = useTranslation('academy');

  return (
    <section id="financing" className={Colors.WHITE_ON_BLUE}>
      <div className="container px-4 py-8 text-center sm:px-8">
        <h2 className="text-4xl font-semibold">{t('financing.title')}</h2>
        {session ? (
          <p>
            {t('financing.training-amount', {
              amount: formatToEuro(session.early_price ?? session.price),
            })}
          </p>
        ) : null}
        <div className="flex flex-wrap justify-center text-black">
          <FundingOpco />
          <FundingPoleEmploi />
          <FundingMonCompteFormation />
        </div>
        <p className="mt-4 whitespace-pre-line">{t('financing.description')}</p>
        <div className="mt-2 flex flex-wrap justify-center">
          <DiscoverLink
            text={t('financing.download-booklet')}
            href="/academy/livret_financement_formation.pdf"
            pseudoClass={`m-2 ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
            svgClass="rotate-90"
          />
          <DiscoverLink
            text={tAcademy('we-talk.button-call')}
            href={tAcademy('we-talk.bookingUrl')}
            pseudoClass={`m-2 ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
            SvgIcon={HeadsetSvgIcon}
          />
        </div>
      </div>
    </section>
  );
};

export default Financing;
