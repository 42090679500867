import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Colors from '../../Colors';
import SvgCaretDown from '../../../images/icons/caretDown.inline.svg';

type SectionProps = {
  number: number;
  title: string;
  description: string;
};

const Section: React.VoidFunctionComponent<SectionProps> = ({ number, title, description }) => {
  const { t } = useTranslation('training');
  const [open, setOpen] = React.useState<boolean>(false);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="container flex h-full max-w-224 flex-col justify-center border-b border-black last:border-0">
      <button
        className="flex w-full items-center justify-between"
        type="button"
        aria-expanded={open}
        aria-controls={`section-${number}`}
        onClick={onClick}
        title={t('program.expandButton', { number: number + 1 })}
      >
        <h3 className="text-left text-xl font-semibold">
          {
            <Trans
              t={t}
              i18nKey={title}
              components={{ minor: <span className="text-base font-medium" /> }}
            >
              trainings.$i18n_key.program.sections.{number}.section
            </Trans>
          }
        </h3>
        <SvgCaretDown
          className={`w-[36px] transform p-[5px] transition-all ${open ? 'rotate-180' : ''}`}
        />
      </button>
      <p
        id={`section-${number}`}
        className={`whitespace-pre-line pb-4 text-sm font-medium text-gray-700 ${
          open ? '' : 'hidden'
        }`}
      >
        {
          <Trans
            t={t}
            i18nKey={description}
            components={{ title: <span className="inline-block pb-2 text-base text-black" /> }}
          >
            trainings.$i18n_key.program.sections.{number}.description
          </Trans>
        }
      </p>
    </div>
  );
};

type ProgramProps = {
  i18n_key: string;
};

const Program: React.VoidFunctionComponent<ProgramProps> = ({ i18n_key }) => {
  const { t } = useTranslation('training');
  const sections = t(`trainings.${i18n_key}.program.sections`, { returnObjects: true });
  const sectionsArray = Array.from(Array(sections.length).keys());

  return (
    <section id="program" className={`px-4 pt-8 ${Colors.WHITE_ON_BLUE}`}>
      <div
        className={`container flex h-full max-w-224 flex-col justify-center px-4 pb-8 pt-4 sm:px-8 ${Colors.BLACK_ON_WHITE}`}
      >
        <h2 className="text-left text-4xl font-semibold">{t('program.title')}</h2>
        {sectionsArray.map((number) => (
          <Section
            key={number}
            number={number}
            title={`trainings.${i18n_key}.program.sections.${number}.section`}
            description={`trainings.${i18n_key}.program.sections.${number}.description`}
          />
        ))}
      </div>
    </section>
  );
};

export default Program;
