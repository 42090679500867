import type { PageProps } from 'gatsby';
// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import type {
  GatsbyImageFileWithName,
  PageContextProps,
  SiteQueryProps,
} from '../../utils/graphql';
import type { GraphqlAcademy } from '../../utils/graphqlNotion';
import NotTranslated from '../NotTranslated';
import Index from './training/Index';
import { useLocation } from '@reach/router';

export type DataType = {
  notionAcademy?: GraphqlAcademy;
  masterImage: GatsbyImageFileWithName;
  ogImage: GatsbyImageFileWithName;
  pictures: {
    nodes: GatsbyImageFileWithName[];
  };
};

type CourseQueryProps = { site: SiteQueryProps } & DataType;
type CourseProps = PageProps<CourseQueryProps, PageContextProps>;

const Course: React.VoidFunctionComponent<CourseProps> = ({ data, pageContext }) => {
  const location = useLocation();
  if (data.notionAcademy) {
    const startDate = new URLSearchParams(location.search).get('date');
    const session = data.notionAcademy.properties.sessions.find((s) => s.start_date === startDate);
    return (
      <Index
        i18n_key={data.notionAcademy.properties.i18n_key}
        title={data.notionAcademy.properties.name}
        status={data.notionAcademy.properties.status}
        abstract={data.notionAcademy.properties.abstract}
        program={data.notionAcademy.properties.program}
        ogImage={data.ogImage}
        locale={pageContext.locale}
        session={session}
      />
    );
  }
  return <NotTranslated pageContext={pageContext} />;
};

export default Course;
