import type { TFunction } from 'i18next';
// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { formatToEuro } from '../../../utils/formatNumber';
import type { Session } from '../../../utils/graphqlNotion';
import { getLocaleDateString } from '../../../utils/formatDate';
import * as Colors from '../../Colors';
import CalendarBlankIcon from '../../../images/academy/CalendarBlank.inline.svg';
import ClockCountDownIcon from '../../../images/academy/ClockCountDown.inline.svg';
import MapPinIcon from '../../../images/academy/MapPin.inline.svg';
import TranslateIcon from '../../../images/academy/Translate.inline.svg';

type EssentialInformationProps = {
  Icon: React.VoidFunctionComponent<React.SVGAttributes<SVGElement>>;
  information: string;
  value: string;
};

const EssentialInformation: React.VoidFunctionComponent<EssentialInformationProps> = ({
  Icon,
  information,
  value,
}) => {
  return (
    <div className="mb-2 grid grid-cols-[24px_auto_minmax(0,1fr)] items-start gap-2">
      <Icon className="h-5 w-5 flex-shrink-0" />
      <span className="min-w-[100px] whitespace-nowrap font-light">{information}</span>
      <span className="break-words font-medium">{value}</span>
    </div>
  );
};

type SessionStickyProps = {
  t: TFunction;
  session: Session | undefined;
  locale: string;
  className: string;
  program: string;
};
const SessionSticky: React.VoidFunctionComponent<SessionStickyProps> = ({
  t,
  session,
  locale,
  className,
  program,
}) => {
  if (!session) return null;
  return (
    <div className={`mb-4 p-4 shadow-lg ${Colors.BLACK_ON_WHITE} ${className}`}>
      <div className={`p-4 ${Colors.BLACK_ON_YELLOW}`}>
        {session.early_price ? (
          <div className="text-2xl font-medium line-through">{formatToEuro(session.price)}</div>
        ) : null}
        <div className="text-3xl font-semibold">
          {formatToEuro(session.early_price ?? session.price)}
        </div>
        {session.early_until_date ? (
          <div className="font-semibold">
            {t('session.until', {
              date: getLocaleDateString(new Date(session.early_until_date), locale),
            })}
          </div>
        ) : null}
        <p className="mt-2 text-xs">{t('session.eligibility')}</p>
      </div>
      <div className="mt-8">
        <p className="font-medium">{t('session.information')}</p>
        <div className="mt-2 max-w-[300px]">
          <EssentialInformation
            Icon={CalendarBlankIcon}
            information={t('session.date')}
            value={getLocaleDateString(new Date(session.start_date), locale)}
          />
          <EssentialInformation
            Icon={ClockCountDownIcon}
            information={t('session.duration')}
            value={t('session.hour', { hour: session.duration })}
          />
          <EssentialInformation
            Icon={MapPinIcon}
            information={t('session.place')}
            value={session.place || ''}
          />
          <EssentialInformation
            Icon={TranslateIcon}
            information={t('session.language')}
            value={session.language ? t(`session.${session.language}`) : 'Paris, France'}
          />
        </div>
        <a
          href="#form"
          rel="noopener noreferrer"
          title={t('session.button')}
          className={`mt-7 inline-block w-full rounded-1 border-1 border-solid border-reacteev-blue px-4 py-3 text-center font-semibold leading-none ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
        >
          {t('session.button')}
        </a>
        <a
          href={program}
          target="_blank"
          rel="noopener noreferrer"
          title={t('session.download-program-button')}
          className={`mt-4 inline-block w-full rounded-1 border-1 border-solid border-reacteev-blue px-4 py-3 text-center font-semibold leading-none ${Colors.BLUE_ON_WHITE} ${Colors.BLUE_ON_WHITE_PSEUDO_CLASS}`}
        >
          {t('session.download-program-button')}
        </a>
      </div>
    </div>
  );
};

export default SessionSticky;
